import React, { useState, useEffect } from 'react';
import classes from './CreatePurchase.module.css';
// import "../../../assets/datatables/dataTables.bootstrap4.min.css";
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import LatestDash from '../LatestDashboard/LatestDash';
import CurrencyInput from 'react-currency-input-field';

const initialState = () => {
  return [{ items: '', unitPrice: '', qty: '', vat: '', discountPercentage: '', discount: '', amount: '' }];
};

const initialState1 = () => {
  return [{ services: '', services2: "",  qty: '', initialAmount: '', vat: '', discountPercentage: '', discount: '', amount: '' }];
};;

export default function CreatePurchaseOrder() {
  const [user, setUser] = useState("");
  const [show, setShow] = useState(false);
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [bankName, setBankName] = useState('');
  const [accName, setAccName] = useState('');
  const [accNo, setAccNo] = useState('');
  const [officeAddress, setOfficeAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [glMethod, setGlMethod] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [creditAccount, setCreditAccounts] = useState([]);
  const [selectedRep, setSelectedRep] = useState('');
  const [formData1, setFormData1] = useState(initialState1);
  const [itemList1, setItemList1] = useState([]);
  const [sICode, setSICode] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [accountName, setAccountName] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalAmount1, setTotalAmount1] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [debitCode, setDebitCode] = useState('');
  const [debitAmount, setDebitAmount] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState(initialState);
  const [itemList, setItemList] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [selectOptions2, setSelectOptions2] = useState([]);
  const [debitAccount, setDebitAccounts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState('');
  const [totalDiscount1, setTotalDiscount1] = useState('');
  const [totalTax, setTotalTax] = useState('');
  const [totalTax1, setTotalTax1] = useState('');
  const [selectedTax, setSelectedTax] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate1, setSelectedDate1] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedExpiryDate, setSelectedExpiryDate] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currency, setCurrency] = useState([]);
  const [currLoading, setCurrLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    product: false,
    service: false,
  });


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);




  const handleCustomerChange = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedCustomer(selectedOption.value);
    }
  };

  const handleSalesChange = (selectedOption) => {
    const selectedSaleRepId = selectedOption.value;
    setSelectedRep(selectedSaleRepId);
  };


  useEffect(() => {
    const calculatedTotalAmount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.amount) || 0),
      0
    );
    // console.log(formData);
    setTotalAmount(calculatedTotalAmount.toFixed(2))
  }, [formData]);


  useEffect(() => {
    const calculatedDiscount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.discount) || 0),
      0
    );
    // console.log(formData);
    setTotalDiscount(calculatedDiscount.toFixed(2))
  }, [formData]);


  useEffect(() => {
    const calculatedTotalAmount1 = formData1.reduce(
      (acc, curr) => acc + (parseFloat(curr.amount) || 0),
      0
    );
    // console.log(formData);
    setTotalAmount1(calculatedTotalAmount1.toFixed(2));
  }, [formData1]);

  useEffect(() => {
    const calculatedDiscount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.discount) || 0),
      0
    );
    // console.log(formData);
    setTotalDiscount(calculatedDiscount.toFixed(2));
  }, [formData]);

  useEffect(() => {
    const calculatedDiscount1 = formData1.reduce(
      (acc, curr) => acc + (parseFloat(curr.discount) || 0),
      0
    );
    // console.log(formData);
    setTotalDiscount1(calculatedDiscount1.toFixed(2));
  }, [formData1]);

  useEffect(() => {
    const calculateTotalVat = () => {
      let totalVatAmount = 0;

      formData.forEach(row => {
        const unitPrice = parseFloat(row.unitPrice) || 0;
        const qty = parseFloat(row.qty) || 0;
        const discountPercentage = parseFloat(row.discountPercentage.replace('%', '')) || 0;
        const discountAmount = (discountPercentage / 100) * (unitPrice * qty);
        const vatRate = row.vat ? parseFloat(row.vat.rate) || 0 : 0;

        // Calculate initial price
        const initialPrice = (unitPrice * qty) - discountAmount;

        // Calculate VAT based on the initial price
        const vatAmount = initialPrice * (vatRate / 100);

        // Accumulate the VAT amount
        totalVatAmount += vatAmount;
      });

      setTotalTax(totalVatAmount.toFixed(2));
    };

    calculateTotalVat();
  }, [formData]);

  useEffect(() => {
    const calculateTotalVat1 = () => {
      let totalVatAmount1 = 0;

      formData1.forEach((row) => {
        const amount = parseFloat(row.amount) || 0;
        const discountPercentage =
          parseFloat(row.discountPercentage.replace("%", "")) || 0;
        const discountAmount = (discountPercentage / 100) * amount;
        const vatRate = row.vat ? parseFloat(row.vat.rate) || 0 : 0;

        // Calculate initial price
        const initialPrice = amount - discountAmount;

        // Calculate VAT based on the initial price
        const vatAmount = initialPrice * (vatRate / 100);

        // Accumulate the VAT amount
        totalVatAmount1 += vatAmount;
      });

      setTotalTax1(totalVatAmount1.toFixed(2));
    };

    calculateTotalVat1();
  }, [formData1]);

  useEffect(() => {
    const calculatedDebit = parseFloat(debitAmount || 0)
    setTotalDebit(calculatedDebit.toFixed(2))
  }, [debitAmount]);


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchCredit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-class-id?class_id=${1}`, { headers });
      const itemss = response.data?.data;

      const options1 = itemss.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setItemList1(itemss);
      setSelectOptions2(options1);
      console.log(itemss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCreditAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDebit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const itemss = response.data?.data;

      const options1 = itemss.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setItemList(itemss);
      setSelectOptions1(options1);
      console.log(itemss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDebitAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    if (bearer) {
      fetchDebit();
      fetchCredit();
    }
  }, [bearer]);


  
  const createSalesInvoice = async () => {
    setCreateLoading(true);

    try {
      const formDatas = new FormData();

      const services = formData1.map((row) => row.services.value).filter((id) => id !== undefined);
      const services2 = formData1.map((row) => row.services2.value).filter((id) => id !== undefined);
      const serviceAmount1 = formData1.map((row) => row.initialAmount).filter((id) => id !== undefined);
      const taxx1 = formData1.map((row) => row.vat.value).filter((id) => id !== undefined);
      const discc1 = formData1.map((row) => row.discount).filter((id) => id !== undefined);
      const disccPercent1 = formData1.map((row) => row.discountPercentage?.replace("%", "")).filter((id) => id !== undefined);
      const quantities = formData1.map((row) => row.qty).filter((id) => id !== undefined);
      const amounts = formData1.map((row) => row.amount).filter((id) => id !== undefined);
      
      
      // const items = formData.map((row) => row.items.value).filter((id) => id !== undefined);
      // const serviceAmount = formData.map((row) => row.unitPrice).filter((id) => id !== undefined);
      // const taxx = formData.map((row) => row.vat.value).filter((id) => id !== undefined);
      // const discc = formData.map((row) => row.discount).filter((id) => id !== undefined);
      // const disccPercent = formData
      //   .map((row) => row.discountPercentage?.replace('%', ''))
      //   .filter((id) => id !== undefined);

      taxx1.forEach((tax, index) => {
        formDatas.append(`tax_id[${index}]`, tax);
      });

      discc1.forEach((discount, index) => {
        formDatas.append(`discount[${index}]`, discount);
      });
      disccPercent1.forEach((discountPercent, index) => {
        formDatas.append(`discount_percentage[${index}]`, discountPercent);
      });

      services.forEach((name, index) => {
        formDatas.append(`account_id[${index}]`, name);
      });

      services2.forEach((name, index) => {
        formDatas.append(`debit_gl_code[${index}]`, name);
      });

      quantities.forEach((quantity, index) => {
        formDatas.append(`quantity[${index}]`, quantity);
      });

      amounts.forEach((amount, index) => {
        formDatas.append(`amount[${index}]`, amount);
      });

      serviceAmount1.forEach((unitPrice, index) => {
        formDatas.append(`service_amount[${index}]`, unitPrice);
      });

      formDatas.append('date_supplied', selectedDate);
      formDatas.append('expiring_date', selectedExpiryDate);
      formDatas.append('document_number', sICode);
      formDatas.append('supplier_id', selectedCustomer);
      // formDatas.append('sales_rep', selectedRep);
      formDatas.append('reference', description);
      formDatas.append(
        "total_amount",
        selectedOption.product ? totalAmount : totalAmount1
      );
      formDatas.append(
        "total_vat",
        selectedOption.product ? totalTax : totalTax1
      );
      formDatas.append(
        "total_discount",
        selectedOption.product ? totalDiscount : totalDiscount1
      );      formDatas.append('option_type', "service");
      formDatas.append('transaction_date', selectedDate1);
      formDatas.append('currency', currency.value || '');


      const response = await axios.post(
        `${BASE_URL}/create-purchase-order`,
        formDatas,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response.data?.message, "heeee");
      fetchInvoiceCode();
      // Reset form fields
      setSICode("");
      setSelectedCustomer("");
      setDebitCode("");
      setSelectedAccountName("");
      setDebitAmount("");
      setDescription("");
      setSelectedDate("");
      setFormData([]);
      setSelectedRep("");

      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const fetchGlMethod = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setGlMethod(resultss);

      // console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/beneficiary`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const custome = response.data?.data;
      const customs = custome.map(item => ({
        label: item.name,
        value: item.id
      }));
      setCustomerList(customs);
      // setAddress(custome);

      // console.log(custome, "itss");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchGlMethod();
      fetchCustomers();
    }
  }, [bearer]);




  const fetchInvoiceCode = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setSICode(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);




  const goBack = () => {
    navigate(-1);
  }






  const addRow = () => {
    const newRow = {
      items: '', unitPrice: '', qty: '', vat: '', discountPercentage: '', discount: '', totalPrice: ''
    };
    setFormData([...formData, newRow]);
  };

  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const deleteRow1 = (index) => {
    const updatedData1 = formData1.filter((_, i) => i !== index);
    setFormData1(updatedData1);
  };

  const addRow1 = () => {
    const newRow1 = {
      services: "", 
      services2: "",  
      qty: "", 
      initialAmount: "", 
      vat: "", 
      discountPercentage: "", 
      discount: "",
      amount: "" ,
    };
    setFormData1([...formData1, newRow1]);
  };


  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const itemss = response.data?.data;

      const options1 = itemss.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setItemList(itemss);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDebitAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTax = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/taxes/company-tax`, { headers });
      const itemss = response.data?.data;
      console.log(itemss)

      const seTax = itemss.map((item) => ({
        label: `${item.short_name} - ${item.rate}`,
        value: item.id,
        rate: item.rate
      }));
      setTaxes(seTax);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTaxes([]);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchSalesRep = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/sales_rep`, { headers });
      const itemss = response.data?.data;

      const sales = itemss.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setSalesRep(sales);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setSalesRep([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemDescriptionChange = (selectedOption, rowIndex) => {
    const selectedItemId = selectedOption.value;
    const selectedItem = itemList.find(item => item.id === selectedItemId);
    const selectedUnitPrice = selectedItem?.price || 0;
    handleFormChange(selectedOption, "items", rowIndex);
    handleFormChange(selectedUnitPrice, "unitPrice", rowIndex);
  };

  const handleFormChange = (value, fieldName, rowIndex) => {
    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      const currentRow = updatedFormData[rowIndex];

      // Update the field that triggered the change
      if (fieldName === 'vat') {
        // If vat is updated, store the whole tax item
        currentRow[fieldName] = value;
      } else {
        currentRow[fieldName] = value;
      }

      // Calculate total price based on qty and unitPrice
      const qty = parseFloat(currentRow.qty) || 0;
      const unitPrice = parseFloat(currentRow.unitPrice) || 0;
      let initialPrice = qty * unitPrice;

      // Calculate discount
      let discountAmount = 0;
      if (fieldName === 'discountPercentage' || fieldName === 'discount') {
        if (fieldName === 'discountPercentage') {
          const discountPercentage = parseFloat(value.replace('%', '')) || 0;
          discountAmount = (discountPercentage / 100) * initialPrice;
          currentRow.discount = discountAmount.toFixed(2);
        } else if (fieldName === 'discount') {
          discountAmount = parseFloat(value) || 0;
          const discountPercentage = (discountAmount / initialPrice) * 100;
          currentRow.discountPercentage = discountPercentage.toString();
        }
      } else {
        const discountPercentage = parseFloat(currentRow.discountPercentage.replace('%', '')) || 0;
        discountAmount = (discountPercentage / 100) * initialPrice;
        currentRow.discount = discountAmount.toFixed(2);
      }

      initialPrice -= discountAmount; // Apply discount to initial price


      // Calculate VAT and add to initial price
      const selectedTax = currentRow.vat;
      const vatRate = selectedTax ? parseFloat(selectedTax.rate) || 0 : 0;
      const vatAmount = (vatRate / 100) * initialPrice;
      const totalPrice = initialPrice + vatAmount;

      currentRow.amount = totalPrice.toFixed(2);

      return updatedFormData;
    });
  };




  const fetchAcctName = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setAccountName(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchAcctName();
      fetchItems();
      fetchTax();
      fetchSalesRep();
    }
  }, [bearer]);


  const formatNumber = (value) => {
    if (!value) return '';
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const parseNumber = (value) => {
    return value.replace(/,/g, '');
  };


  const handleFormChange1 = (value, fieldName, rowIndex) => {
    setFormData1(prevFormData => {
      const updatedFormData1 = [...prevFormData];
      const currentRow1 = updatedFormData1[rowIndex];
  
      if (fieldName === 'vat') {
        
        if (value.value === 'add-new') {
          handleShow();  
          return updatedFormData1; // Exit early after showing the modal
        } else {
          currentRow1[fieldName] = value; // Assign the selected VAT
        }
      } else {
        currentRow1[fieldName] = value; // Handle other field updates
      }
  
      // Calculate total price based on qty and unitPrice
      const initialAmount = parseFloat(currentRow1.initialAmount) || 0;
      const qty = parseFloat(currentRow1.qty) || 0;
      let initialPrice = initialAmount * qty;
  
      // Calculate discount
      let discountAmount = 0;
      if (fieldName === 'discountPercentage' || fieldName === 'discount') {
        if (fieldName === 'discountPercentage') {
          const discountPercentage = parseFloat(value.replace('%', '')) || 0;
          discountAmount = (discountPercentage / 100) * initialPrice;
          currentRow1.discount = discountAmount.toFixed(2);
        } else if (fieldName === 'discount') {
          discountAmount = parseFloat(value) || 0;
          const discountPercentage = (discountAmount / initialPrice) * 100;
          currentRow1.discountPercentage = discountPercentage.toString();
        }
      } else {
        const discountPercentage = parseFloat(currentRow1.discountPercentage.replace('%', '')) || 0;
        discountAmount = (discountPercentage / 100) * initialPrice;
        currentRow1.discount = discountAmount.toFixed(2);
      }
  
      initialPrice -= discountAmount; // Apply discount to initial price
  
      // Calculate VAT and add to initial price
      const selectedTax = currentRow1.vat;
      const vatRate = selectedTax ? parseFloat(selectedTax.rate) || 0 : 0;
      const vatAmount = (vatRate / 100) * initialPrice;
      const totalPrice = initialPrice + vatAmount;
  
      currentRow1.amount = totalPrice.toFixed(2);
  
      return updatedFormData1;
    });
  };

  const handleItemDescriptionChange1 = (selectedOption, rowIndex) => {
    const selectedItemId = selectedOption.value;
    // const selectedItem = itemList.find(item => item.id === selectedItemId);
    // const selectedAmount = selectedItem?.price || 0;
    handleFormChange1(selectedOption, "services", rowIndex);
    // handleFormChange1(selectedAmount, "initialAmount", rowIndex);
};


const handleItemDescriptionChange2 = (selectedOption, rowIndex) => {
  const selectedItemId = selectedOption.value;
  const selectedItem = itemList.find(item => item.id === selectedItemId);
  // const selectedAmount = selectedItem?.price || 0;
  handleFormChange1(selectedOption, "services2", rowIndex);
  // handleFormChange1(selectedAmount, "initialAmount", rowIndex);
};



  
  const extendedCustomerList = [
    { label: '(Add a new supplier)', value: 'add-new' },
    ...customerList
  ];

  const createCustomer = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/beneficiary/add`,
        {
          name: fullName,
          email: email,
          phone_number: phone,
          address: address,
          bank_name: bankName,
          account_name: accName,
          account_number: accNo
        },
        { headers }
      );
      console.log(response.data.message)
      const newCustomer = {
        label: response.data.data.name,
        value: response.data.data.id
      };

      console.log(newCustomer);

      setCustomerList([...customerList, newCustomer]);
      setSelectedCustomer(newCustomer.value);

      fetchCustomers();
      setFullName('');
      setEmail('');
      setPhone('');
      setAddress('');
      setBankName('');
      setAccName('');
      setAccNo('');
      handleClose();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      const errorStatus = error.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: JSON.stringify(error.response.data.message),
      });
      // toast.error(JSON.stringify(error.response.data.message));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrency = async () => {
    setCurrLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-all-currencies`, { headers });
      const results = response.data?.data;
      // console.log(results, "currencis")
      const options = results.map((item) => ({
        label: item.code,
        value: item.id,
        symbol: item.symbol
      }));
      setCurrency(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCurrency([]);
    } finally {
      setCurrLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCurrency();
    }
  }, [bearer]);


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleDateChange1 = (event) => {
    setSelectedDate1(event.target.value);
  };
  const handleExpiryChange = (event) => {
    setSelectedExpiryDate(event.target.value);
  };



  return (
    <div>
      <ToastContainer />
      <LatestDash />

      <div className={classes.finishedbodyCont}>



        <h3 className={classes.productText}>Create Purchases Order</h3>
        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            <div className={classes.formLabel}>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add New Supplier</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      // autoFocus
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      // autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone Number"
                      // autoFocus
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Input Address"
                      // autoFocus
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Supplier's Bank Name"
                      // autoFocus
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Supplier's Account Name"
                      // autoFocus
                      value={accName}
                      onChange={(e) => setAccName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Supplier's Account Number"
                      // autoFocus
                      value={accNo}
                      onChange={(e) => setAccNo(e.target.value)}
                    />

                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createCustomer}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating Supplier, Please wait...</span>
                    </>
                  ) : (
                    "Create Supplier"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="d-flex justify-content-center">
              <Form style={{ marginTop: 20, width: '90%' }}>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Order To</Form.Label>
                        <div style={{ width: 500 }}>
                          <Select loading={loading} options={extendedCustomerList} placeholder='Select Supplier' onChange={handleCustomerChange}
                            menuPortalTarget={document.body}
                            styles={{

                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                maxWidth: '700px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>PV Number</Form.Label>
                        <Form.Control
                          className={classes.formControl}
                          type="text"
                          // placeholder="Enter Description of the Product"
                          value={sICode}
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ marginTop: -10 }}>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Reference</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          name="description"
                        // style={{ width: "1000px" }}
                        />

                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6" >
                    <div className="d-flex align-items-center form-group-custom">
                      <Form.Label className={classes.formLabelCustom}>Supporting Doc.</Form.Label>
                      <Form.Group className="mb-3">
                        <div >

                          <Form.Control
                            type="file"
                            placeholder="Choose file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={handleImageChange}
                            style={{ width: 385, display: "flex", justifyContent: "left" }}
                          />

                        </div>
                      </Form.Group>
                    </div>
                  </div>

                </div>
                <div className="row" style={{ marginTop: -10, }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Date</Form.Label>
                        <input
                          className="form-control"
                          required=""
                          type="date"
                          onChange={handleDateChange}
                          name="date"
                          value={selectedDate}

                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Expiry Date</Form.Label>
                        <input
                          className="form-control"
                          required=""
                          type="date"
                          onChange={handleExpiryChange}
                          name="date"
                          value={selectedExpiryDate}

                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ marginTop: -10, }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Transaction Date</Form.Label>
                        <input
                          className="form-control"
                          required=""
                          type="date"
                          onChange={handleDateChange1}
                          name="date"
                          value={selectedDate1}

                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Currency</Form.Label>
                        {/* {formData.map((row, index) => ( */}
                        {/* // <tr > */}
                        {/* <td style={{ width: "200px" }}> */}
                        <div style={{ width: 450 }}>
                          <Select
                            // value={currency}
                            onChange={setCurrency}
                            options={selectOptions}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                // maxWidth: '200px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>
                        {/* </td> */}
                        {/* </tr> */}
                        {/* ))} */}

                      </div>
                    </Form.Group>
                  </div>


                </div>


              </Form>
            </div>
            <div className={classes.buttonsGroup}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="row" style={{ maxWidth: "100%" }}>

                  <h5 style={{ textAlign: "left", marginTop: '30px' }}>Add Service(s)</h5>
                  {/* <div className="col-md-6"> */}
                  <div className="table-responsive">
                    <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">
                      <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                        <tr>
                          <th style={{ width: '150px' }}>Debit Account</th>
                          <th style={{ width: '150px' }}>Credit Account</th>
                          <th style={{ width: '100px' }}>Unit Price(N)</th>
                          <th style={{ width: '70px' }}>Qty</th>
                          <th style={{ width: '10px' }}>Discount(%)</th>
                          <th style={{ width: 150 }}>Discount</th>
                          <th style={{ width: 100 }}>V.A.T</th>
                          <th style={{ width: 120 }}>Total Price(N)</th>
                          <th style={{ width: '1px' }}>
                            <div className="btn btn-sm printbtninv" onClick={() => addRow1()}>
                              <i className="fas fa-plus" style={{ color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: "nowrap" }}>

                        {formData1.map((row, index) => (
                          <tr key={index}>
                            <td style={{ width: "200px" }}>
                              <div style={{ width: 170 }}>
                              <Select
                                  value={row.services2}
                                  onChange={(selectedOption) => handleItemDescriptionChange2(selectedOption, index)}
                                  options={selectOptions1}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                      ...provided,
                                      maxHeight: '300px',
                                      // maxWidth: '200px',
                                      overflowY: 'auto',
                                    }),
                                  }}
                                />
                              </div>
                            </td>
                            <td style={{ width: "200px" }}>
                              <div style={{ width: 170 }}>
                              <Select
                                  value={row.services}
                                  onChange={(selectedOption) => handleItemDescriptionChange1(selectedOption, index)}
                                  options={selectOptions2}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                      ...provided,
                                      maxHeight: '300px',
                                      // maxWidth: '200px',
                                      overflowY: 'auto',
                                    }),
                                  }}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100px' }}>
                              <input
                                type="text"
                                name={`rowinitialAmount ${index}`}
                                decimalsLimit={2}
                                value={row.initialAmount}
                                className="form-control"
                                onChange={(e) => handleFormChange1(e.target.value, "initialAmount", index)}
                                // disabled
                                style={{ textAlign: "right", border: "none", width: '7rem' }}
                              />
                              {/* <CurrencyInput
                  name={`rowUnitPrice ${index}`}
                  decimalsLimit={2}
                  value={row.unitPrice}
                  className="form-control"
                  disabled
                  style={{ textAlign: "right", border: "none", width: '7rem' }}
                /> */}
                            </td>
                            <td >
                              <input
                                type="text"
                                className="form-control"
                                value={row.qty}
                                onChange={(e) => handleFormChange1(e.target.value, "qty", index)}
                                // onBlur={calculateFormData}
                                style={{ width: '70px' }}
                              />
                            </td>
                            <td style={{ width: '10px' }}>
                              <input
                                type="text"
                                className="form-control"
                                value={row.discountPercentage}
                                onChange={(e) => handleFormChange1(e.target.value, "discountPercentage", index)}
                                onBlur={(e) => {
                                  const percentage = parseFloat(e.target.value.replace('%', '')) || 0;
                                  handleFormChange1(percentage + '%', "discountPercentage", index);
                                }}
                                onFocus={(e) => {
                                  handleFormChange1(row.discountPercentage.replace('%', ''), "discountPercentage", index);
                                }}
                                style={{ width: '80px' }}
                              />
                            </td>
                            <td style={{ width: '100px' }}>
                              <input
                                type="text"
                                className="form-control"
                                value={row.discount}
                                onChange={(e) => handleFormChange1(e.target.value, "discount", index)}
                                // onFocus={(e) => {
                                //   const cleanValue = parseNumber(e.target.value);
                                //   e.target.value = cleanValue;
                                // }}
                                // onBlur={(e) => {
                                //   const formattedValue = formatNumber(e.target.value);
                                //   e.target.value = formattedValue;
                                //   handleFormChange(e.target.value, "discount", index);
                                // }}
                                style={{ textAlign: "right", border: "none", width: '7rem' }}
                              />
                            </td>
                            <td style={{ width: "100px" }}>
                              <div style={{ width: 150 }}>
                                {/* <Form.Select
  name="tax" 
  className="form-control" 
  required 
  value={row.vat} 
  onChange={(e) => handleFormChange(e.target.value, "vat", index)} 
  style={{width: "100px"}}
>
  <option value="">Select</option>
  {taxes.map((item) => (
    <option key={item.id} value={item.id}>
      {item.short_name} - {item.rate}
    </option>
  ))}
</Form.Select> */}

                                <Select
                                  onChange={(selectedOption) => handleFormChange1(selectedOption, "vat", index)}
                                  options={taxes}
                                  value={row.vat}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                      ...provided,
                                      maxHeight: '300px',
                                      overflowY: 'auto',
                                    }),
                                  }}
                                />
                              </div>
                            </td>

                            <td >
                              <CurrencyInput
                                name={`rowLineTotal ${index}`}
                                decimalsLimit={2}
                                value={row.amount}
                                className="form-control"
                                disabled
                                onChange={(e) =>
                                  handleFormChange1(
                                    e.target.value,
                                    "totalAmount",
                                    index
                                  )
                                }
                                style={{ textAlign: "right", border: "none", }}

                              />
                            </td>
                            <td >
                              <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow1(index)}>
                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12, }}></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 20, display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: "18rem" }} >
                <div className="col-md-6" style={{ marginLeft: '10px' }}>
                  <Form.Group className="mb-3">
                    <div className="d-flex align-items-center form-group-custom">
                      <Form.Label className={classes.formLabelCustom}>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        // value={description}
                        // onChange={(e) => setDescription(e.target.value)}
                        name="description"
                        style={{ width: "1000px" }}
                      />

                    </div>
                  </Form.Group>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: 10, justifyContent: 'flex-end' }}>
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', gap: '5px' }}>
                    <label for="example-text-input" style={{ whiteSpace: "nowrap", textAlign: "right" }}>Total V.A.T:</label>
                    <div style={{ padding: '0', width: '10rem', }}>

                      {/* <Select
                      value={row.items} 
                      onChange={(selectedOption) => handleItemDescriptionChange(selectedOption, index)}
                      options={selectOptions1}
                      menuPortalTarget={document.body}
                      styles={{

                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (provided) => ({
                          ...provided,
                          maxHeight: '100px',
                          overflowY: 'auto',

                        }),

                      }}
                    /> */}
                      <CurrencyInput
                        name='total-amount'
                        decimalsLimit={2}
                        value={totalTax1}
                        className="form-control"
                        disabled
                        style={{ textAlign: "right", border: "none", width: '10rem' }}
                      />

                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', gap: '5px' }}>
                    <label for="example-text-input" style={{ whiteSpace: "nowrap", textAlign: "right" }}>Total Discount:</label>
                    <div style={{ padding: '0' }}>
                      <CurrencyInput
                        name='total-amount'
                        decimalsLimit={2}
                        value={totalDiscount1}
                        className="form-control"
                        disabled
                        style={{ textAlign: "right", border: "none", width: '10rem' }}
                      />

                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end", gap: '5px', alignItems: 'center', }}>
                    <label for="example-text-input" style={{ whiteSpace: "nowrap", textAlign: "right" }}>Total Amount:</label>
                    <div style={{ padding: '0' }}>
                      <CurrencyInput
                        name='total-amount'
                        decimalsLimit={2}
                        value={totalAmount1}
                        className="form-control"
                        disabled
                        style={{ textAlign: "right", border: "none", width: '10rem' }}
                      />

                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.bttn} style={{ marginTop: '40px' }}>
                <Button variant="light" className={classes.btn1} onClick={goBack}> Cancel</Button>
                <Button disabled={!totalAmount} style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {createLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button disabled style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {/* {createLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Send to Mail"
                          )} */}
                  Send to Mail
                </Button>
                <Button disabled style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {/* {createLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Print in PDF"
                          )} */}
                  Print in PDF
                </Button>
              </div>
            </div>









          </div>
        </div>

      </div>






    </div >
  )
}